import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import { routes } from "./routes";
import Login from "./views/LoginPage";
import Home from "./views/HomePage";
import AdminRoute from "./utils/AdminRoutes";
import Admin from "./views/admin/AdminDashboard";
import AdminProductGridPage from "./views/admin/AdminProduct/AdminProductGridPage"; 
import AdminProductFormPage from "./views/admin/AdminProduct/AdminProductFormPage";
import AdminCategoryGridPage from "./views/admin/AdminCategory/AdminCategoryGridPage";
import AdminCategoryFormPage from "./views/admin/AdminCategory/AdminCategoryFormPage";

function App() {
  return (
    <div className="App min-h-screen">
      <Routes>
        <Route element={<PrivateRoutes />}>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={<route.element />} />
          ))}
          <Route path="*" element={<Home />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin/product" element={<AdminProductGridPage />} />
          <Route
            path="/admin/product/create"
            element={<AdminProductFormPage />}
          />
          <Route
            path="/admin/product/edit/:id"
            element={<AdminProductFormPage />}
          />

          <Route path="/admin/category" element={<AdminCategoryGridPage />} />
          <Route
            path="/admin/category/create"
            element={<AdminCategoryFormPage />}
          />
          <Route
            path="/admin/category/edit/:id"
            element={<AdminCategoryFormPage />}
          />

          <Route path="/admin/*" element={<Admin />} />
        </Route>
        <Route element={<Login />} path="/login" />
      </Routes>
    </div>
  );
}

export default App;
