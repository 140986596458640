import { Link, useNavigate } from "react-router-dom";
import { useGetAuthorizedUser } from "../hooks/auth";

const Header = () => {
  const navigate = useNavigate();
  const getAuthorizedUserQuery = useGetAuthorizedUser();

  const logOut = ()=>{
    localStorage.removeItem('access_token')
    navigate("/login");
  }

  return (
    <header className="flex justify-between border-b items-center px-4 sm:px-7 py-3 sm:py-4">
      <Link to="/">
        <button className="bg-black text-white py-2 px-2 rounded-md">
          i-Mobile
        </button>
      </Link>
      <nav>
        <ul className="flex gap-x-5">
          <li>
            <Link to="/">Главная </Link>
          </li>
          {getAuthorizedUserQuery?.data?.role === "admin" && (
            <li>
              <Link to="/admin">Админ</Link>
            </li>
          )}
          <li className="cursor-pointer" onClick={logOut}>Выйти</li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
