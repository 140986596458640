import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FaPen, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button, Box } from "@mui/material";
import {
  useCategoryDeleteMutation,
  useGetAllCategoriesQuery,
} from "../../../hooks/category";

const AdminCategoryGridPage = () => {
  const getAllCategoriesQuery = useGetAllCategoriesQuery();
  const data = getAllCategoriesQuery?.data || [];
  const delCategoryQuery = useCategoryDeleteMutation();

  if (getAllCategoriesQuery?.isFetching) {
    return <div>Загрузка...</div>;
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Название",
      minWidth: 200,
      // renderCell: (params) => (
      //   <span style={{ fontWeight: 'bold' }}>
      //     {params.row.categoryId?.name}
      //   </span>
      // ),
    },
    {
      field: "actions",
      headerName: "Действия",
      minWidth: 150,
      renderCell: (params) => (
        <div className="flex h-full gap-x-6 items-center">
          <Link to={`/admin/category/edit/${params.row.id}`}>
            <FaPen className="cursor-pointer text-blue-500" />
          </Link>
          <FaTrash
            className="cursor-pointer text-red-500"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id: string) => {
    delCategoryQuery
      .mutateAsync({ id })
      .then(() => {
        if (window.confirm("Вы точно хотите удалить?")) {
          getAllCategoriesQuery.refetch();
        }
      })
      .catch((e) => console.log("e", e));
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="flex justify-between">
        <h1 className="text-2xl mb-4">Категория</h1>
        <Box>
          <Link to="/admin/category/create">
            <Button variant="contained" size="small">
              Создать
            </Button>
          </Link>
        </Box>
      </div>
      <DataGrid
        rows={data || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 10]}
        // checkboxSelection
      />
    </div>
  );
};

export default AdminCategoryGridPage;
