type Props = {
  node: any;
  level?: number;
};

const CategoryItem = ({ node, level = 0 }: Props) => {
  const children = node.children && node.children.length > 0;
  const paddingLeft = level * 20;

  return (
    <>
      <option
        key={node.id}
        value={node.id}
        className="p-2"
        style={{ paddingLeft, margin: "0 10px" }}
      >
        {node.name}
      </option>
      {children &&
        node.children.map((item: any) => (
          <CategoryItem key={item.id} node={item} level={level + 1} />
        ))}
    </>
  );
};

export default CategoryItem;
