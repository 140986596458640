import React from "react";
import { sidebarLinks } from "../../../constants";
import { Link, useLocation } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

type Props = {
  open: boolean;
  toggleSidebar: () => void;
};

const MobileSidebar = ({ open, toggleSidebar }: Props) => {
  const url = useLocation();

  return (
    <div
      className={`mobileSidebar absolute z-3 text-white w-[250px] min-w-[250px] bg-[#212631] h-screen translate-x-[-250px] z-10 transition-all ${
        open ? "translate-x-[0px]" : ""
      }`}
    >
      <button className="absolute right-0 top-0 p-2" onClick={toggleSidebar}>
        <IoMdClose />
      </button>
      <div className="p-4 border-b border-[#323a49] text-center">iMobile</div>
      <div className="min-h-screen p-2">
        {sidebarLinks.map((item) => {
          return (
            <Link key={item.route} to={item.route}>
              <div
                className={`p-3 hover:bg-[#2A303D] hover:rounded-md hover:pointer ${
                  url.pathname === item.route && "bg-[#2A303D]"
                }`}
              >
                {item.label}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default MobileSidebar;
