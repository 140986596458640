import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify'
import App from "./App";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./http/queryClient";
import 'react-toastify/dist/ReactToastify.css'
import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <ToastContainer/>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>
);
