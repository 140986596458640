export const sidebarLinks = [
  {
    route: "/admin",
    label: "Главная",
  },
  {
    route: "/admin/product",
    label: "Товары",
  },
  {
    route: "/admin/category",
    label: "Категория",
  },
];
