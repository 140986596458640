import React from "react";

type Props = {};

const Footer = (props: Props) => {
  return (
    <div className="mt-auto py-5 px-7 text-center border-t bg-white text-zinc-600">
      &copy; 2024 All rights reserved
    </div>
  );
};

export default Footer;
