import React from "react";
import CategoryItem from "../components/CategoryItem";

type Props = {
  list: any;
};

const CategoryList = ({ list }: Props) => {
  return (
    <div className="border-2 border-gray-200 rounded p-3 ">
      <ul>
        {list?.map((node: any) => (
          <CategoryItem key={node._id} node={node} />
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
