import React, { useState } from "react";
import { useLogin } from "../hooks/auth";
import { useNavigate, Navigate } from "react-router-dom";

type loginFormModel = {
  fullName: string;
  password: string;
}[];

const DefaultLoginFormModel: loginFormModel = [
  {
    fullName: "",
    password: "",
  },
];

const Login = () => {
  const navigate = useNavigate();
  const loginQuery = useLogin();
  const [errorMessages, setErrorMessages] = useState<loginFormModel>(
    DefaultLoginFormModel
  );
  const [mes, setMes] = useState({ message: "" });
  const auth = localStorage.getItem("access_token");
  if (auth) {
    return <Navigate to="/" />;
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const fullName = formData.get("fullName") as string;
    const password = formData.get("password") as string;
    const data = { fullName, password };

    const loginPromise = loginQuery.mutateAsync({ data });
    loginPromise
      .then((res) => {
        localStorage.setItem("access_token", res.data.token);
        navigate("/");
      })
      .catch((e: any) => { 
        if (e.response?.status === 400) {
          setErrorMessages(e.response.data);
          setMes({ message: "" });
        }
        if (e.response?.status === 404) {
          setMes(e.response.data);
          setErrorMessages(DefaultLoginFormModel);
        }
      });
  };

  return (
    <main className="min-h-screen flex justify-center items-center">
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-y-2 p-5 bg-white rounded-md shadow-sm max-w-[350px] w-full m-3"
      >
        <p className="mb-3 text-center">Авторизация</p>
        <ul>
          {errorMessages.length > 0 && errorMessages?.map((item: any, index) => (
            <li key={index} className="text-red-500 text-xs">
              {item.msg}
            </li>
          ))}
          <div className="text-red-500 text-xs">{mes?.message || ""}</div>
        </ul>
        <input
          name="fullName"
          type="text"
          placeholder="Логин"
          className="border p-2 pl-3 rounded-md"
        />
        <input
          name="password"
          type="password"
          placeholder="Пароль"
          className="border p-2 pl-3 rounded-md"
        />
        <button
          disabled={loginQuery.isLoading}
          type="submit"
          color="primary"
          className={`bg-zinc-900 text-white border rounded-md p-2 ${
            loginQuery.isLoading && "opacity-40"
          }`}
        >
          {loginQuery.isLoading ? "Ждите..." : "Войти"}
        </button>
      </form>
    </main>
  );
};

export default Login;
