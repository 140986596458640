import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FaPen, FaTrash } from "react-icons/fa";
import {
  useGetAllProducts,
  useProductDeleteMutation,
  useToggleProductMutation,
} from "../../../hooks/products";
import { Link } from "react-router-dom";
import { Button, Box, Switch } from "@mui/material";

const AdminProductGridPage = () => {
  const [products, setProducts] = useState<any>([]);
  const getAllProductsQuery = useGetAllProducts();
  const delProductQuery = useProductDeleteMutation();
  const updateAvailabilityProductQuery = useToggleProductMutation();

  useEffect(() => {
    setProducts(getAllProductsQuery.data);
  }, [getAllProductsQuery.data]);

  if (getAllProductsQuery?.isFetching) {
    return <div>Загрузка...</div>;
  }

  const columns: GridColDef[] = [
    { field: "title", headerName: "Название", minWidth: 150,flex: 1 },
    {
      field: "categoryId",
      headerName: "Категория",
      minWidth: 100,
      flex: 1,
      valueGetter: (asd, row) => {
        return row?.categoryId?.name;
      },
    },
    {
      field: "availability",
      headerName: "На складе",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.availability}
            onChange={(e) => handleSwitchChange(e, params.row.id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Действия",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <div className="flex h-full gap-x-6 items-center">
          <Link to={`/admin/product/edit/${params.row.id}`}>
            <FaPen className="cursor-pointer text-blue-500" />
          </Link>
          <FaTrash
            className="cursor-pointer text-red-500"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ];

  const handleSwitchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const newChecked = e.target.checked;
    setProducts((prev: any) => {
      return prev?.map((item: any) =>
        item.id === id ? { ...item, availability: newChecked } : item
      );
    });

    try {
      const newChecked = e.target.checked;
      updateAvailabilityProductQuery.mutateAsync({
        form: { availability: newChecked, id },
      });
    } catch (error) {
      console.error("Error toggling product availability:", error);
    }
  };

  const handleDelete = (id: string) => {
    delProductQuery
      .mutateAsync({ id })
      .then(() => {
        if (window.confirm("Вы точно хотите удалить?")) {
          getAllProductsQuery.refetch();
        }
      })
      .catch((e) => console.log("e", e));
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="flex justify-between">
        <h1 className="text-2xl mb-4">Товары</h1>
        <Box>
          <Link to="/admin/product/create">
            <Button variant="contained" size="small">
              Создать
            </Button>
          </Link>
        </Box>
      </div>
      <Box sx={{width: '100%'}} className="bg-white">
        <DataGrid
          rows={products || []}
          columns={columns}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 30]}
          // checkboxSelection
        />
      </Box>
    </div>
  );
};

export default AdminProductGridPage;
