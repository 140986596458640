import React from "react";
import {useGetTreeCategoriesQuery } from "../hooks/category";

import CategoryList from "../components/CategoryList";

const Home = () => {
  const getTreeCategoriesQuery = useGetTreeCategoriesQuery(); 

  if(getTreeCategoriesQuery.isLoading){
    return <div className="text-center">Загрузка...</div>;
  }

  return (
    <div >
      <CategoryList list={getTreeCategoriesQuery.data} />
    </div>
  );
};

export default Home;
