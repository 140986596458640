import React from "react";

const Footer = () => {
  return (
    <div className="mt-auto py-5 px-7 text-center border-t text-zinc-400">
      <small>&copy; All rights reserved</small> 
    </div>
  );
};

export default Footer;
