import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import {
  useCategoryFormQuery,
  useCategorySaveMutation,
  useGetTreeCategoriesQuery,
} from "../../../hooks/category";

import CategoryItem from "../components/CategoryItem";
import {
  Category,
  CategoryFormModel,
  DefaultCategoryFormModel,
} from "../../../models/categoryModels";

type Props = {};

const AdminCategoryFormPage = (props: Props) => {
  const [model, setModel] = useState<CategoryFormModel>(
    DefaultCategoryFormModel
  );
  const { id } = useParams<{ id: string }>();
  const getTreeCategoriesQuery = useGetTreeCategoriesQuery();
  const getOneCategoryQuery = useCategoryFormQuery(id);
  const saveMutation = useCategorySaveMutation(id);

  useEffect(() => {
    if (id) {
      getOneCategoryQuery.refetch().then(({ data }) => {
        if (data) {
          setModel({ name: data.name, parent: data.parent });
        }
      });
    } else {
      setModel(DefaultCategoryFormModel);
    }
  }, [id]);

  const handleChange = (event: any) => {
    const {
      target: { value, name },
    } = event;
    setModel((prev) => ({ ...prev, [name]: value }));
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    saveMutation
      .mutateAsync({ form: model })
      .then(({ data }: { data: Category }) => {
        const { name, parent } = data;
        if (!id) {
          setModel(DefaultCategoryFormModel);
        } else {
          setModel({ name, parent });
        }
        toast.success("Категория успешно сохранен!");
      })
      .catch((e) => toast.error(e));
  };

  return (
    <div className="bg-white rounded p-3 min-h-full">
      <h1 className="text-2xl mb-4">Редактировать категорию</h1>
      <Box component="form" className="sm:w-full md:w-1/2">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              type="text"
              label="Название категории"
              variant="outlined"
              fullWidth
              value={model.name}
              name="name"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <select
              onChange={handleChange}
              name="parent"
              value={model.parent || ""}
              className="rounded w-full border border-[#c4c4c4] p-2 focus:border-["
            >
              <option value="">Выберите род. категорию</option>
              {getTreeCategoriesQuery?.data?.map((category) => (
                <CategoryItem key={category.id} node={category} />
              ))}
            </select>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={submitForm}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AdminCategoryFormPage;
