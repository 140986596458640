import { useMutation, useQuery } from "react-query";
import axios, { requestWithoutAuth } from "../http";

type LoginType = {
  fullName: string;
  password: string;
};
export const useLogin = () => {
  return useMutation(({ data }: { data: LoginType }) => {
    return requestWithoutAuth.post(`/auth/login`, data);
  });
};

export const useGetAuthorizedUser = () => {
  return useQuery(["get-authorized-user"], async () => {
    const { data } = await axios.get("/auth/getMe");
    return data
  });
};
