import React from "react";
import Container from "./container";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Container>
        <Header />
        <main className="p-4 sm:px-7">{children}</main>
        <Footer />
      </Container>
    </>
  );
};

export default Layout;
