import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Layout from "../components/layout";

const PrivateRoutes = () => {
  const token = localStorage.getItem("access_token");

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoutes;
