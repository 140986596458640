import { useMutation, useQuery } from "react-query";
import axios from "../http";
import { AxiosError } from "axios";

export const useGetTreeCategoriesQuery = () => {
  return useQuery<any[], AxiosError>(["get-cats-tree"], async () => {
    const { data } = await axios.get("/category/tree");
    return data;
  });
};

export const useGetAllCategoriesQuery = () => {
  return useQuery<any[]>(["get-cats-all"], async () => {
    const { data } = await axios.get("/category/all");
    return data;
  });
};

export const useCategoryFormQuery = (id: string | undefined) => {
  return useQuery(["get-one-category-form"], async () => {
    if (id) {
      const { data } = await axios.get(`/category/get-one/${id}`);
      return data;
    }
  });
};

export const useCategorySaveMutation = (id: string | undefined) => {
  const mutationFn = ({ form }: { form: any }) => {
    if (id) {
      return axios.put(`/category/update/${id}`, form);
    }
    return axios.post("/category/create", form);
  };
  return useMutation(mutationFn);
};

export const useCategoryDeleteMutation = () => {
  return useMutation(({ id }: { id: string }) =>
    axios.delete(`/category/delete/${id}`)
  );
};
