import { sidebarLinks } from "../../../constants";
import { Link, useLocation } from "react-router-dom";

type Props = {
  // open: boolean;
  // setOpen: (val: boolean) => void;
};

const Sidebar = (props: Props) => {
  const url = useLocation();
  //${open ? "md:block" : "hidden"}
  return (
    <div className={` bg-[#212631] text-white hidden md:block min-w-[250px]`}>
      <div className="p-4 border-b border-[#323a49] text-center">iMobile</div>
      <div className="min-h-screen p-2">
        {sidebarLinks.map((item) => {
          return (
            <Link key={item.route} to={item.route}>
              <div
                className={`p-3 hover:bg-[#2A303D] hover:rounded-md hover:pointer ${
                  url.pathname === item.route && "bg-[#2A303D]"
                }`}
              >
                {item.label}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
