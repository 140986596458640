import React from 'react'

type Props = {}

const Admin = (props: Props) => {
  return (
    <div>Admin</div>
  )
}

export default Admin