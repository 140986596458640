import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useGetTreeCategoriesQuery } from "../../../hooks/category";
import {
  DefaultProductFormModel,
  Product,
  ProductFormModel,
} from "../../../models/productModels";
import {
  useProductFormQuery,
  useProductSaveMutation,
} from "../../../hooks/products";
import CategoryItem from "../components/CategoryItem";

type Props = {};

const AdminProductFormPage = (props: Props) => {
  const [model, setModel] = useState<ProductFormModel>(DefaultProductFormModel);
  const { id } = useParams<{ id: string }>();
  const getTreeCategoriesQuery = useGetTreeCategoriesQuery();
  const getProductForm = useProductFormQuery(id);
  const saveMutation = useProductSaveMutation(id);

  useEffect(() => {
    if (id) {
      getProductForm.refetch().then(({ data }) => {
        if (data) {
          setModel({
            title: data.title,
            categoryId: data.categoryId,
            price: data.price,
          });
        }
      });
    } else {
      setModel(DefaultProductFormModel);
    }
  }, [id]);

  const handleChange = (event: any) => {
    const {
      target: { value, name },
    } = event;
    setModel((prev) => ({ ...prev, [name]: value }));
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    saveMutation
      .mutateAsync({ form: model })
      .then(({ data }: { data: Product }) => {
        const { title, categoryId, price } = data;
        if (!id) {
          setModel(DefaultProductFormModel);
        } else {
          setModel({ title, categoryId, price });
        }
        toast.success("Товар успешно сохранен!");
      })
      .catch((e) => toast.error(e));
  };

  return (
    <div className="bg-white rounded p-3 min-h-full">
      <h1 className="text-2xl mb-4">Редактировать товар</h1>
      <Box component="form" className="sm:w-full md:w-1/2">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Название товара
            </Typography>
            <TextField
              size="small"
              type="text"
              // label="Название товара"
              variant="outlined"
              fullWidth
              value={model.title}
              name="title"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Выберите категорию
            </Typography>
            <select
              onChange={handleChange}
              name="categoryId"
              value={model.categoryId}
              className="rounded w-full border border-[#c4c4c4] p-2 focus:border-["
            >
              <option value=""> </option>
              {getTreeCategoriesQuery?.data?.map((category) => (
                <CategoryItem key={category.id} node={category} />
              ))}
            </select>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Цена
            </Typography>
            <TextField
              size="small"
              type="number"
              variant="outlined"
              fullWidth
              value={model.price}
              name="price"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={submitForm}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AdminProductFormPage;
