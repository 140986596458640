export type Category = {
  createdAt?: string;
  id?: string;
  name: string;
  parent: string | null;
  updatedAt?: string;
  __v?: number;
  _id?: string;
};

export type CategoryFormModel = {
  name: string | null;
  parent: string | null;
};

export const DefaultCategoryFormModel: CategoryFormModel = {
  name: "",
  parent: "",
};
