import React from "react";
import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import MobileSidebar from "./MobileSidebar";

type Props = {
  children: React.ReactNode;
};

const AdminLayout = ({ children }: Props) => {
  const [open, setOpen] = React.useState(false);

  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <div className="flex">
      <Sidebar  />
      <MobileSidebar open={open} toggleSidebar={toggleSidebar}/>
      <div className="w-full flex flex-col">
        <Header toggleSidebar={toggleSidebar} />
        <main className="p-4 min-h-screen">{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default AdminLayout;
