export type Product = {
  author?: null;
  categoryId: string;
  createdAt?: string;
  description?: string;
  image?: string;
  title: string;
  updatedAt?: string;
  views?: number;
  __v?: number;
  _id: string;
  price: number;
};

export type ProductFormModel = {
  title: string;
  categoryId: string;
  price: number;
};

export const DefaultProductFormModel: ProductFormModel = {
  title: "",
  categoryId: "",
  price: 0
};
