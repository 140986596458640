import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const requestWithoutAuth = axios.create({
  baseURL: API_URL,
});

const $inctance = axios.create({
  baseURL: API_URL,
});

$inctance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "access_token"
  )}`;
  return config;
});

export default $inctance;
