import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const CategoryItem = ({ node }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const children = node.children && node.children.length > 0;

  return (
    <li className="pl flex ">
      {children > 0 && (
        <IoIosArrowForward
          className={`transform ${
            isOpen ? "rotate-90" : ""
          } translate-y-2  duration-200`}
        />
      )}
      <div className={`${!children ? "pl-6" : "pl-2"}`}>
        <div className="cursor-pointer p-1" onClick={handleToggle}>
          {!children ? (
            <Link to={`/category/${node._id} `} >
              {node.name}
            </Link>
          ) : (
            node.name
          )}
        </div>
        {children && (
          <ul className={`${isOpen ? "block duration-1000" : "hidden"}`}>
            {node.children.map((child: any) => (
              <CategoryItem key={child._id} node={child} />
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};

export default CategoryItem;
