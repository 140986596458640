import { Link, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";

type Props = {
  toggleSidebar: () => void;
};

const Header = ({ toggleSidebar }: Props) => {
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("access_token");
    navigate("/login");
  };
  return (
    <div className="p-4 border-b border-[#dbdfe6] flex justify-between bg-white  ">
      <div className="flex gap-x-4 items-center">
        <div onClick={toggleSidebar} className="cursor-pointer block md:hidden">
          <RxHamburgerMenu />
        </div>
        <Link to="/">
          <div className="hover:underline">Перейти на сайт</div>
        </Link>
      </div>
      <ul className="flex gap-x-4">
        <li className="cursor-pointer" onClick={logOut}>
          Выйти
        </li>
      </ul>
    </div>
  );
};

export default Header;
