import { useParams } from "react-router-dom";
import { useGetProductsByCategory } from "../hooks/products";
import { FcOk, FcCancel } from "react-icons/fc";

const Category = () => {
  const { id } = useParams();
  const getProductsByCategoryQuery = useGetProductsByCategory(id);

  if (getProductsByCategoryQuery.isFetching) {
    return <div className="text-center">Загрузка...</div>;
  }

  if (getProductsByCategoryQuery.isError) {
    return <div className="text-center">Ошибка загрузки данных</div>;
  }

  const categoryList = getProductsByCategoryQuery.data || [];

  return (
    <div>
      <div>
        {/* <div className="flex justify-end mb-3">
          <Link to="/">
            <button className=" flex items-center">
              <IoIosArrowBack className="mr-1 h-4" />
              Назад
            </button>
          </Link>
        </div> */}
        <h1 className="text-2xl text-center mb-4">
          {categoryList[0]?.categoryId?.name}
        </h1>
      </div>
      <div className="">
        {categoryList?.length > 0 ? (
          getProductsByCategoryQuery.data?.map((item) => (
            <div
              key={item._id}
              className="flex justify-between items-center border-b "
            >
              <div className="mb-2 mt-2">{item.title}</div>
              <div className="flex items-center">
                <div className=" ">{item.price} т</div>
                <div className="ml-3">
                  {item.availability ? (
                    <div className="text-green-600">
                      <FcOk />
                    </div>
                  ) : (
                    <div>
                      <FcCancel />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <h1 className="text-center">Категория пуста</h1>
        )}
      </div>
    </div>
  );
};

export default Category;
