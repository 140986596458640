import { Navigate, Outlet } from "react-router-dom";
import AdminLayout from "../views/admin/components/AdminLayout";

const AdminRoute = () => {
  const token = localStorage.getItem("access_token");
  const role: string = "admin";

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (role !== "admin") {
    return <Navigate to="/" />;
  }

  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};

export default AdminRoute;
