import { useMutation, useQuery } from "react-query";
import axios from "../http";
import { Product, ProductFormModel } from "../models/productModels";

export const useGetProductsByCategory = (id: string | undefined) => {
  return useQuery<any[]>(["get-products-by-category"], async () => {
    const { data } = await axios.get(`/product/byCategory/${id}`);
    return data;
  });
};

export const useGetAllProducts = () => {
  return useQuery<Product[]>(
    ["get-all-products"],
    async () => {
      const { data } = await axios.get("/product");
      return data;
    },
    { enabled: true }
  );
};

export const useProductSaveMutation = (id: string | undefined) => {
  const mutationFn = ({ form }: { form: ProductFormModel }) => {
    if (id) {
      return axios.put(`/product/${id}`, form);
    }
    return axios.post("/product/create", form);
  };
  return useMutation(mutationFn);
};

export const useProductFormQuery = (id: string | undefined) => {
  return useQuery<Product>(["product-get-form"], async () => {
    if (id) {
      const { data } = await axios.get(`/product/${id}`);
      return data;
    }
  });
};

export const useProductDeleteMutation = () => {
  return useMutation(({ id }: { id: string }) =>
    axios.delete(`/product/${id}`)
  );
};

export const useToggleProductMutation = () => {
  return useMutation(({ form }: { form: { availability: boolean; id: string } }) => {
    return axios.post(`/product/availability`, form);
  });
};
